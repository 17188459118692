import gallary1 from "../../assets/images/gallary1-new.png";
import gallary4 from "../../assets/images/gallary4-new.png";
import gallary5 from "../../assets/images/gallary5-new.png";
import gallary6 from "../../assets/images/gallary6-new.png";
import gallary8 from "../../assets/images/gallary5.jpg";
import gallary7 from "../../assets/images/gallary6.jpg";
import gallary9 from "../../assets/images/gallary3.jpg";
import gallary10 from "../../assets/images/gallary2.jpg";
import gallary11 from "../../assets/images/gallary4.jpg";
import gallary12 from "../../assets/images/gallary11.jpg";
import gallary13 from "../../assets/images/gallary9.jpg";
import gallary14 from "../../assets/images/gallary12.jpg";
import gallary15 from "../../assets/images/gallary13.jpg";
import gallary16 from "../../assets/images/gallary14.jpg";
import gallary17 from "../../assets/images/gallary15.jpg";
import gallary18 from "../../assets/images/gallary16.jpg";
import gallary19 from "../../assets/images/gallary17.jpg";
import gallary20 from "../../assets/images/gallary18.jpg";
import gallary21 from "../../assets/images/gallary19.jpg";
import gallary22 from "../../assets/images/gallary21.jpg";
import Slider from "react-slick";
import { about_slider, about_slider_forth, about_slider_second, about_slider_third } from "../../config/setting";

const Gallary = () => {
  return (
    <div className="gallary-home">
      <div className="container">
        <div className="main-content">
          <div className="row gallary-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 content">
              <h1 className="main-h text-center">BRIDAL MAKE-UP</h1>

              <div className="gallery">
                <div className="gallery__strip__wrapper">
                  <div className="gallery__strip one">
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary1} alt="Kyoto" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary10} alt="Halstatt" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary9} alt="Peru" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary4} alt="Rio" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary5} alt="Rio" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary6} alt="Rio" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gallery__strip__wrapper">
                  <div className="gallery__strip two">
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary7} alt="Italy" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary8} alt="Osaka" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary12} alt="Bali" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary13} alt="Paris" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary14} alt="Cusco" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary15} alt="Rome" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gallery__strip__wrapper">
                  <div className="gallery__strip three">
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary16} alt="Paris" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary17} alt="Bali" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary18} alt="Bali" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary19} alt="Bali" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gallery__strip__wrapper">
                  <div className="gallery__strip four">
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary20} alt="Milan" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary11} alt="Budapest" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary21} alt="Vienna" />
                      </div>
                    </div>
                    <div className="photo">
                      <div className="photo__image">
                        <img src={gallary22} alt="Mexico" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-container">
          <h1 className="main-h text-center mb-5">BRIDAL MAKE-UP</h1>

            <Slider {...about_slider} className="gallary-caro-over ">
              <div className="content-first">
                <img src={gallary1} alt="Kyoto" />
              </div>

              <div className="content-second">
                <img src={gallary10} alt="Kyoto" />
              </div>

              <div className="content-third ">
                {" "}
                <img src={gallary9} alt="Kyoto" />
              </div>
              <div className="content-first">
                {" "}
                <img src={gallary4} alt="Kyoto" />
              </div>
              <div className="content-second">
                <img src={gallary5} alt="Kyoto" />
              </div>
              <div className="content-third">
                {" "}
                <img src={gallary6} alt="Kyoto" />
              </div>
            </Slider>
          </div>

          <div className="slider-container">
            <Slider {...about_slider_second} className="gallary-caro-over ">
              <div className="content-first">
                <img src={gallary7} alt="Kyoto" />
              </div>

              <div className="content-second">
                <img src={gallary7} alt="Kyoto" />
              </div>

              <div className="content-third ">
                {" "}
                <img src={gallary12} alt="Kyoto" />
              </div>
              <div className="content-first">
                {" "}
                <img src={gallary13} alt="Kyoto" />
              </div>
              <div className="content-second">
                <img src={gallary14} alt="Kyoto" />
              </div>
              <div className="content-third">
                {" "}
                <img src={gallary15} alt="Kyoto" />
              </div>
            </Slider>
          </div>

          <div className="slider-container">
            <Slider {...about_slider_third} className="gallary-caro-over ">
              <div className="content-first">
                <img src={gallary16} alt="Kyoto" />
              </div>

              <div className="content-second">
                <img src={gallary17} alt="Kyoto" />
              </div>

              <div className="content-third ">
                {" "}
                <img src={gallary18} alt="Kyoto" />
              </div>
              <div className="content-second">
                {" "}
                <img src={gallary19} alt="Kyoto" />
              </div>
              
            </Slider>
          </div>

          <div className="slider-container">
            <Slider {...about_slider_forth} className="gallary-caro-over ">
              <div className="content-first">
                <img src={gallary20} alt="Kyoto" />
              </div>

              <div className="content-second">
                <img src={gallary11} alt="Kyoto" />
              </div>

              <div className="content-third ">
                {" "}
                <img src={gallary21} alt="Kyoto" />
              </div>
              <div className="content-second">
                {" "}
                <img src={gallary22} alt="Kyoto" />
              </div>
              
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
