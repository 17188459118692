import { useEffect } from "react";
import about from "../../assets/images/about-main.png"
import { NavLink } from "react-router-dom";

const About = () => {
  document.title = "About US | HEMMY'S BRIDAL STUDIO";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="about top-sec">
        <div className="container">
          <div className="main-content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 content">
                <h1 className="main-h">
                  ABOUT US
                </h1>
                <h6 className="p-txt">
                  Welcome! Learn all about my career as a certified artist right
                  here. As a professional in the industry, my main focus is
                  providing my clients with customized treatments or services
                  that suit their individual needs. I’ve accumulated many years
                  of experience. Have a look at the services I offer, and get in
                  touch to learn more.
                </h6>

                <h6 className="p-txt"> I’ve accumulated many years
                  of experience. Have a look at the services I offer, and get in
                  touch to learn more.
                </h6>

                <NavLink to="/book-appointment">
                  <button className="primary-btn mt-4">
                    Book An Appointment
                  </button>
                  </NavLink>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 sec-content">
                <div className="img-box">
                  <img src={about} className="img-fluid" alt="home-new" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
