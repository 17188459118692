import { useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ChangePasswordValidation } from "../../config/setting";
import { CHANGE_PASSWORD } from "../../config/apiUrl";

const ChangePassword = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  document.title = "Change Password | HEMMY'S BRIDAL STUDIO";

  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const Login = async () => {
    const { errors, isError } = ChangePasswordValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);

        const response = await axiosInstance.post(CHANGE_PASSWORD, userData);
        if (response) {
          setLoader(false);
          navigate("/login");

          toast.success(response.data.message);
        }
      } catch (error) {
        setLoader(false);

        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="login top-sec">
        <div className="container ">
          <div className="main-content">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-8 col-12 col-sm-12 ">
                <div className="card">
                  <h1 className="main-h text-center mb-4">Change Password</h1>
                  <div className="form-main-div">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlpass"
                            placeholder="Enter Old Password"
                            autoComplete="off"
                            value={userData?.old_password || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "old_password")
                            }
                          />
                          <p className="red-color">{error?.old_password}</p>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="tetx"
                            className="form-control"
                            id="exampleFormControlpass"
                            placeholder="Enter New Password"
                            autoComplete="off"
                            value={userData?.new_password || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "new_password")
                            }
                          />
                          <p className="red-color">{error?.new_password}</p>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="tetx"
                            className="form-control"
                            id="exampleFormControlpass"
                            placeholder="Enter Confirm Password"
                            autoComplete="off"
                            value={userData?.confirm_password || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "confirm_password")
                            }
                          />
                          <p className="red-color">{error?.confirm_password}</p>
                        </div>
                      </div>

                      <div className="col-xl-12 d-flex justify-content-center mb-4 mt-3">
                        <button
                          className="primary-btn login"
                          type="button"
                          onClick={() => Login()}
                        >
                          {loader === true ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
