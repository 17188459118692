import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import { useState } from "react";
import toast from "react-hot-toast";
import { SENT_FORGOT_PASSWORD } from "../../config/apiUrl";
import { SentForgotEmailValidation } from "../../config/setting";
import Modal from "react-modal";

const SendForgotEmail = () => {
  document.title = "Forgot Password | HEMMY'S BRIDAL STUDIO";
  const [userData, setUserData] = useState({});
  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "1111",
      width: "500px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "40px",
    },
  };

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const ForgotPassword = async () => {
    const { errors, isError } = SentForgotEmailValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);
        const response = await axiosInstance.post(
          SENT_FORGOT_PASSWORD,
          userData
        );
        if (response) {
          setLoader(false);
          setMessage(response.data.message);
          setIsOpen(true);
          toast.success(response.data.message);
        }
      } catch (error) {
        setLoader(false);

        toast.error(error.response.data.message);
      }
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="login forgot-pass top-sec">
        <div className="container">
          <div className="main-content">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-6 col-12 col-sm-12 ">
                <div className="card">
                <h1 className="main-h text-center mb-4">Reset Your Password</h1>

                  <div className="form-main-div">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Email"
                            autoComplete="off"
                            value={userData?.email || ""}
                            onChange={(e) =>
                              handleChange(e.target.value, "email")
                            }
                          />
                          <p className="red-color">{error?.email}</p>
                        </div>
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center mb-4 mt-3">
                        <button
                          className="primary-btn login"
                          type="button"
                          onClick={() => ForgotPassword()}
                        >
                          {loader === true ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                            </>
                          ) : (
                            "Submit   "
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-div">
          <h1 className="name">
            An email has been sent to your registered address to reset your
            password
          </h1>
          <NavLink to="/">
            {" "}
            <div className="btn header-btn mt-5" style={{ zIndex: "1111" }}>
              Go To Home
            </div>
          </NavLink>
        </div>
      </Modal>
    </>
  );
};
export default SendForgotEmail;
