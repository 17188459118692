import { useEffect, useState } from "react";
import axiosInstance from "../../config/axiosInstance";
import { GET_APPOINTMENT_LIST } from "../../config/apiUrl";
import Loader from "../../components/Loader";
import moment from "moment";

const AppointmentList = () => {
  document.title = "Appointment List | HEMMY'S BRIDAL STUDIO";

  const [appointmentList, setAppointmentList] = useState([]);

  const [pageLoad, setPageLoad] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState();
  const [totalResult, setTotalresult] = useState();
  const from = page * limit - limit + 1;
  const to = totalPages === page ? totalResult : limit * page;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAppointmentList();
  }, [page]);

  const getAppointmentList = async () => { 
    setPageLoad(true);
    try {
      const response = await axiosInstance.get(
        GET_APPOINTMENT_LIST + "?limit=" + limit + "&page=" + page
      );
      if (response) {
        setPageLoad(false);
        setAppointmentList(response.data.payload.results);
        setTotalPages(response.data.payload.totalPages);
        setPage(response.data.payload.page);
        setLimit(response.data.payload.limit);
        setTotalresult(response.data.payload.totalResults);
      }
    } catch (error) {
      setAppointmentList([]);
    }
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onPrev = () => {
    setPage(page - 1);
  };

  return (
    <>
      <div className="get-in-touch top-sec">
        <div className="container">
        <div className="main-content">

          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
            <h1 className="main-h text-center mb-5">Appointment List</h1>

              <table className="table table-striped  table-appoitment">
                <thead>
                  <tr>
                    <th scope="col">Index</th>
                    <th scope="col">Service Name</th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentList && appointmentList.length > 0 ? (
                    appointmentList.map((item, index) => (
                      <tr key={index}>
                        <td>{(page - 1) * limit + index + 1}</td>
                        <td>{item?.service_name}</td>
                        <td>{item?.employ_name}</td>
                        <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                        <td>{item?.time}</td>
                        <td>
                          <span
                            className={
                              item.status === "pending"
                                ? "badge badge-warning"
                                : item.status === "cancelled"
                                ? "badge badge-danger"
                                : "badge badge-success"
                            }
                          >
                            {item?.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h1 className="no-data">No Data Found</h1>
                  )}
                </tbody>
              </table>
            </div>

            {totalResult > 0 && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 mt-5">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center align-items-center">
                    <li
                      className={
                        page === 1
                          ? "page-item prev-btn disabled"
                          : "page-item prev-btn"
                      }
                    >
                      <a
                        className="page-link prev-btn"
                        aria-disabled={page === 1 ? true : false}
                        onClick={page > 1 ? () => onPrev() : null}
                        role="buton"
                      >
                        Previous{" "}
                      </a>
                    </li>

                    <li className="page-item flex align-items-center ml-3 mr-3">
                      <span>
                        Showing {from} to {to} of {totalResult} entries
                      </span>
                    </li>
                    <li
                      className={
                        page === totalPages
                          ? "page-item next-btn disabled"
                          : "page-item next-btn "
                      }
                      onClick={page < totalPages ? () => onNext() : null}
                    >
                      <a className="page-link next-btn" role="buton">
                        {" "}
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
        </div>
      </div>
      {pageLoad === true && <Loader />}
    </>
  );
};

export default AppointmentList;
