import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  BOOK_APPOINTMENT,
  GET_EMPLOYEE,
  GET_SERVICE,
  GET_SLOT,
} from "../../config/apiUrl";
import toast from "react-hot-toast";
import axios from "../../config/axiosInstance";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";
import {
  AppiontmentFormValidation,
  convertServerDate,
} from "../../config/setting";
import AuthContext from "../../context/authContext";

const BookAppointment = () => {
  document.title = "Book Appointment | HEMMY'S BRIDAL STUDIO";

  const [btnLoader, setBtnLoader] = useState(false);
  const [bookLoader, setBookLoader] = useState({});
  const [hasChecked, setHasChecked] = useState(false);
  const [date, setDate] = useState(new Date());
  const [service, setService] = useState();
  const [employee, setEmployee] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [error, setErrors] = useState({});
  const [availableSlotsList, setAvailableSlotsList] = useState([]);

  const [serviceList, setServiceList] = useState([]);

  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!window.cn(token)) {
      getServiceDetails();
      getEmployeeList();
    }
  }, []);

  const getServiceDetails = async () => {
    try {
      const response = await axios.get(GET_SERVICE);

      if (response) {
        const serviceData = response?.data?.payload.map((item) => ({
          label: item.service_name,
          value: item._id,
        }));
        setServiceList(serviceData);
      }
    } catch (error) {
      setServiceList([]);
    }
  };

  const getEmployeeList = async () => {
    try {
      const response = await axios.get(GET_EMPLOYEE);

      if (response) {
        const employeeData = response?.data?.payload.results.map((item) => ({
          label: item.first_name + " " + item.last_name,
          value: item._id,
        }));
        setEmployeeList(employeeData);
      }
    } catch (error) {
      setEmployeeList([]);
    }
  };
  const getSlotList = async () => {
    const { errors, isError } = AppiontmentFormValidation(
      date,
      service,
      employee
    );
    setErrors(errors);
    if (!isError) {
      try {
        setBtnLoader(true);
        const response = await axios.get(
          GET_SLOT +
            "?date=" +
            convertServerDate(date) +
            "&employId=" +
            employee.value
        );
        if (response) {
          const { payload } = response?.data;
          setAvailableSlotsList(payload?.slots);
          setBtnLoader(false);
        }
        setHasChecked(true);
      } catch (error) {
        setBtnLoader(false);
      }
    }
  };


  // Get Availble Time Slots Data
  const getAvailableSlotsData = () => {
    if (hasChecked) {
      if (!window.cn(availableSlotsList) && availableSlotsList.length > 0) {
        return availableSlotsList.map(function (availableSlots, index) {
          return (
            <div className="book_slot mt-4 " key={index}>
              <ul>
                <li>
                  <span>Time</span>
                  <p>{availableSlots.time}</p>
                </li>
                <li>
                  <span>Status</span>
                  <p>
                    {availableSlots.isBooked === true ? (
                      <span
                        className="badge badge-danger"
                        style={{ color: "#FFF" }}
                      >
                        Full
                      </span>
                    ) : (
                      <span
                        className="badge badge-success"
                        style={{ color: "#FFF" }}
                      >
                        Available
                      </span>
                    )}
                  </p>
                </li>
              </ul>

              <button
                type="button"
                className="primary-btn login"
                onClick={() => bookAppointment(availableSlots._id)}
                disabled={availableSlots.isBooked === true ? true : false}
              >
                {bookLoader[availableSlots._id] ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  <>
                    <span>Book Appointment</span>
                  </>
                )}
              </button>
            </div>
          );
        });
      } else {
        return <h1 className="no-data-found mt-5">No Available Appointment</h1>;
      }
    }
  };

  const bookAppointment = async (id) => {
    if (!window.cn(token)) {
      try {
        setBookLoader((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        const requestData = {
          slotId: id,
          serviceId: service.value,
          employId: employee.value,
        };
        const response = await axios.post(BOOK_APPOINTMENT, requestData);
        if (response) {
          setBookLoader((prevState) => ({
            ...prevState,
            [id]: true,
          }));
          setService("");
          setEmployee("");
          getSlotList();

          toast.success(response.data.message);
          navigate("/appointment-list");
        }
      } catch (error) {
        if (error.response) {
          setBookLoader((prevState) => ({
            ...prevState,
            [id]: true,
          }));
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error("Oops! You must be logged in to book an appointment.");
    }
  };

  return (
    <>
      <div className="book-appointment top-sec">
        <div className="container">
          <div className="main-content">
            <div className="card">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 mb-5">
                  <h1 className="main-h text-center">Book Appointment</h1>
                </div>
                {!window.cn(token) ? (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 mb-4">
                      <label style={{ color: "#fff" }}>Select Service</label>
                      <Select
                        placeholder="Select Service"
                        options={serviceList}
                        onChange={(value) => setService(value)}
                        value={service}
                        className="mb-2 react-select-control"
                      />
                      <p className="red-color">{error?.service}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 mb-4">
                      <label style={{ color: "#fff" }}>Select Employee</label>
                      <Select
                        placeholder="Select Employee"
                        options={employeeList}
                        onChange={(value) => setEmployee(value)}
                        value={employee}
                        className="mb-2 react-select-control"
                      />
                      <p className="red-color">{error?.employee}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 mb-4">
                      <div className="calender react-calender">
                        <Calendar
                          onChange={setDate}
                          value={date}
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 mb-4">
                      <button className="primary-btn" type="button"
                              onClick={() => getSlotList()}
                      
                      >
                        {btnLoader == true ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          </>
                        ) : (
                          "Check Appointments"
                        )}
                      </button>
                      {getAvailableSlotsData()}
                    </div>
                  </>
                ) : (
                  <div className="col-xl-12 col-12 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="main-message mb-5">
                      Oops! You must be logged in to book an appointment.
                    </h1>

                    <NavLink to="/login">
                      <button className="primary-btn login" type="button">
                        Login
                      </button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
