import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo-h.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="main-content">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                <div className="single-footer-caption mb-50">
                  {/* <!-- logo --> */}
                  <div className="footer-logo">
                    <NavLink to="/" className="d-flex align-items-center ">
                      <img src={logo} alt="" className="img-fluid logo" />
                      {/* <h6 className="mb-0 ml-3 header-text">INDULGENCE</h6> */}
                    </NavLink>{" "}
                  </div>

                  <div className="footer-number">
                    <h4>
                      <span>+44 </span>7380663510
                    </h4>
                    <h4>
                      <span>+44 </span>7534950538
                    </h4>
                    <a
                      href="mailto:hemmyshennaart@gmail.com"
                      target="_blank"
                    >
                      <p>hemmyshennaart@gmail.com</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5 section-t">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Location </h4>
                    <div className="footer-pera">
                      <p className="info1">
                      12 Axtell way, Weston turville, Aylesbury HP22 3BF                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5 section-t">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Explore</h4>
                    <ul>
                      <li>
                        <NavLink to="/about-us">About</NavLink>
                      </li>

                      <li>
                        <NavLink to="/contact-us">Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 section-t">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Get Updates</h4>
                    <div className="footer-pera">
                      <p className="info1">
                        Subscribe now to get daily updates
                      </p>
                    </div>
                  </div>
                  {/* <!-- Form --> */}
                  <div className="footer-form">
                    <div id="mc_embed_signup">
                      <input
                        type="email"
                        name="EMAIL"
                        id="newsletter-form-email"
                        placeholder=" Email Address "
                        className="placeholder hide-on-focus"
                      />
                      <div className="form-icon">
                        <button
                          type="submit"
                          name="submit"
                          id="newsletter-submit"
                          className="email_icon newsletter-submit button-contactForm"
                        >
                          Send
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-xl-9 col-lg-8">
                <div className="footer-copy-right"></div>
              </div>
              <div className="col-xl-3 col-lg-4 d-flex justify-content-end">
                {/* <!-- Footer Social --> */}
                <div className="footer-social f-right">
                  <a href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="">
                    <i className="fas fa-globe"></i>
                  </a>
                  <a href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
